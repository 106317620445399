<template>
    <j-modal
        :title="title"
        :width="width"
        :visible="visible"
        switchFullscreen
        @ok="handleOk"
        :okButtonProps="{ class:{'jee-hidden': disableSubmit} }"
        @cancel="handleCancel"
        cancelText="关闭"
        ok-text="确认">
        <j-form-container :disabled="disableSubmit">
            <a-form-model ref="form" :model="model" :rules="validatorRules" slot="detail">
                <!-- 人才库 -->
                <a-row v-if="!model.isRecruit">
                    <a-col :span="24">
                        <a-form-model-item label="姓名" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="name">
                            <a-input v-model="model.name" placeholder="请输入姓名"  ></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="电话" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="phone">
                            <a-input v-model="model.phone" placeholder="请输入电话"  ></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="出生年月" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="age">
                            <JDate v-model="model.birthDate" style="width:100%;"></JDate>
                            <!-- <a-input-number v-model="model.age" placeholder="请输入年龄"  style="width:100%;"></a-input-number> -->
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="学历" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="wxNumber">
                            <a-input v-model="model.education" placeholder="请输入学历"  ></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="政治面貌" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="wxNumber">
                            <a-input v-model="model.politicsStatus" placeholder="请输入政治面貌"  ></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="证书" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="wxNumber">
                            <a-radio-group v-model="model.isCertificate" :options="options" style="width:30%"/>
                            <a-input v-model="model.certificate" placeholder="请输入证书"  v-if="model.isCertificate==1" style="width:70%"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="资质" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="wxNumber">
                            <a-radio-group v-model="model.isCertification" :options="options" style="width:30%"/>
                            <a-input v-model="model.certification" placeholder="请输入资质" v-if="model.isCertification==1" style="width:70%"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="微信号" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="wxNumber">
                            <a-input v-model="model.wxNumber" placeholder="请输入微信号"  ></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="工作年限" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="workNumber">
                            <a-input-number v-model="model.workNumber" placeholder="请输入工作年限"  style="width:100%"></a-input-number>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="应聘岗位" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="typeForJob">
                            <a-input v-model="model.typeForJob" placeholder="请输入应聘岗位"  ></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="简历附件" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="resume">
                            <j-upload v-model="model.resume"></j-upload>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="remark">
                            <a-input v-model="model.remark" placeholder="请输入备注"  ></a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <!-- 招聘库 -->
                <a-row v-else>
                    <a-col :span="24">
                        <a-form-model-item label="招聘人" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="username">
                            <a-input v-model="model.username" placeholder="请输入招聘人"  ></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="被招聘人" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="byUsername">
                            <!-- <a-textarea v-model="model.byUsername" placeholder="请选择" readOnly auto-size></a-textarea> -->
                            <JSearchSelect 
                                :multi="true" 
                                :url="'hr-server/hr/hrRecruitmentInfo/get/user'"
                                :placeholder="'请输入名称查询'"
                                :params="{pageNo:1,pageSize:10}"
                                :displayKey="'name'"
                                :labelKeys="['name','deptname']"
                                @selRows="selectRow"></JSearchSelect>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
        </j-form-container>
    </j-modal>
</template>

<script>
import {
    // getRecruitPool,
	addRecruitPool,
	addTalentPool
} from '@/api/api';
// import {delWeight} from '@/utils/util'
export default {
    // name:'招聘/人才交流库'
    name: 'PoolModal',
    data() {
        let validatePass = (rule, value, callback) =>{
            // console.log('=========validatePass',this.selItem, value)
            if(this.selItem.length) {
                callback();
            }else {
                callback(new Error());
            }
        }
        return {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
            options:[
                { label: '有', value: 1 },
                { label: '无', value: 0 },
            ],
            title:'',
            width:800,
            visible: false,
            disableSubmit: false,
            model:{},
            validatorRules: {
                byUsername:[{validator: validatePass,message: '请输入搜索选择'}]//required: true, message: '请输入搜索选择',
            },
            //选择的数据
            selItem:[],
        }
    },
    computed: {},
    methods:{
        add (record) {
            this.visible = true;
            this.model = {...record};
            console.log('=======',this.model.isRecruit);
        },
        //选择返回
        selectRow(value) {
            console.log('==========返回值',value);
            value.forEach(el =>{
                el.name = JSON.parse(el.key).name;
                el.id = JSON.parse(el.key).id;
                el.deptid = JSON.parse(el.key).deptid;
                el.deptname = JSON.parse(el.key).deptname;
            })
            this.selItem = value;
        },
        //处理参数
        handlerParam(arr) {
            let reArr = []
            arr.length && arr.forEach(el => {
                let temp = {};
                temp.userId = this.model.userId;
                temp.username = this.model.username;
                temp.deptId = el.deptid;
                temp.deptName = el.deptname;
                temp.byUserId = el.id;
                temp.byUsername= el.name;
                reArr.push(temp);
            })
            return reArr
        },
        handleOk() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    let params = null;
                    if(this.model.isRecruit) {
                        params = this.handlerParam(this.selItem);
                        console.log('======参书招聘',params);
                        // return
                        addRecruitPool(params).then(res => {
                            this.resMsg(res);
                        })
                    }else {
                        params = this.model;
                        console.log('======参书人才',params);
                        // return
                        addTalentPool(params).then(res => {
                            this.resMsg(res);
                        })
                    }
                }
            })
        },
        //反馈提示
        resMsg(res) {
            if(res.success) {
                this.$Jnotification.success({
                    message: '提示',
                    description: res.message,
                    duration: 0,
                });
                this.handleCancel();
                this.$emit('subOk');
            }else {
                this.$Jnotification.error({
                    message: '错误提示',
                    description: res.message,
                    duration: 0,
                })
            }
        },
        handleCancel() {
            this.visible = false;
            this.model = {}
        }
    }
}
</script>

<style scoped lang="less">

</style>