<template>
  <a-spin tip="Loading..." :spinning="spinning" class="detail_list">
    <header class="seal_heard">
      <span class="title">员工工作清单表</span>
      <div class="btn">
        <a-input v-model="username" placeholder="请输入姓名回车搜索" style="width:100%;"
          @pressEnter="searchQuery" allowClear @change="searchQuery"/>
      </div>
    </header>
    <!-- table区域-begin -->
    <div class="main">
      <a-table
        ref="table"
        size="middle"
        bordered
        :rowKey="(record,index) => index"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange">
          <span slot="role" slot-scope="text">
            <span>{{role[text]}}</span>
          </span>
          <span slot="action" slot-scope="text, record">
            <a @click="promoteDetail(record)">工作详情</a>
          </span>

      </a-table>
    </div>
  </a-spin>
</template>

<script>

  import {getDict,getUserList} from '@/api/api'
  export default {
    name: 'HrUserRoleList',
    mixins:[],
    components: {},
    data () {
      return {
        description: '金慧用户角色管理页面',
        loading:false,
        spinning:false,
        // 表头
        columns: [
          {
            title: '#',
            dataIndex: '',
            key:'rowIndex',
            width:60,
            align:"center",
            customRender:function (t,r,index) {
              return parseInt(index)+1;
            }
          },
          {
            title:'用户名称',
            align:"center",
            dataIndex: 'username'
          },
          {
            title:'所属部门',
            align:"center",
            dataIndex: 'deptName'
          },
          // {
          //   title:'角色',
          //   align:"center",
          //   dataIndex: 'roleId',
          //   scopedSlots: { customRender: 'role' }
          // },
          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            fixed:"right",
            width:200,
            scopedSlots: { customRender: 'action' }
          }
        ],
        pagination:{
          pageNo:1,
          pageSize:15,
          total:0,
        },
        dataSource:[],
        role:{},
        username:''
      }
    },
    created() {
      // this.getDictItem('role');
    },
    computed: {
      reQuery() {
        return this.$route.query
      },
      userId() {
        return this.reQuery.userId
      }
    },
    watch:{
      'userId':{
        handler(val) {
          this.initData(val)
        },
        immediate:true
      }
    },
    methods: {
      //根据字典Code, 初始化字典数组
      getDictItem(code) {
        getDict({ code })
        .then((res) => {
            if (res.success) {
                let arr = code.split(';')
                arr.forEach(item=>{
                    res.result[item].forEach(element=>{
                        this.$set(this[item], element.value, element.text)
                    })
                });
            }
        })
      },
      //初始化
      initData(val) {
        getUserList({
          pageNo:this.pagination.pageNo,
          pageSize:this.pagination.pageSize,
          userid:val,
          username:`*${this.username}*`
        }).then(res => {
          if(res.success) {
            console.log('=========外联返回数据',res.result);
            this.dataSource = res.result.records;
            this.pagination.total = res.result.total;
          }else {
            this.$notification.error({
                message: '错误提示',
                description: res.message,
                duration: 0,
            })
          }
        })
      },
      //翻页
      handleTableChange(pagination) {
        this.pagination.pageNo = pagination.current;
        this.pagination.pageSize = pagination.pageSize;
        this.initData();
      },
      //搜索
      searchQuery() {
        this.pagination.pageNo = 1;
        this.initData();
      },
      //详情
      promoteDetail(record) {
        console.log('=======详情',record);
        this.$router.push({
          path:'/HR-platform/promoteChart',
          query:record
        })
      },

    }
  }
</script>
<style scoped lang="less">
.seal_heard{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #002b45;
    color: #fff;
    font-size: 14px;
    text-align: center;
    .title{flex:1;text-align: center;}
    .btn{margin-right: 20px;}
  }
  .main{padding: 20px;}
/** [表格主题样式一] 表格强制列不换行 */
/deep/.j-table-force-nowrap {
  td, th {
    white-space: nowrap;
  }

  .ant-table-selection-column {
    padding: 12px 22px !important;
  }

  /** 列自适应，弊端会导致列宽失效 */
  &.ant-table-wrapper .ant-table-content {
    overflow-x: auto;
  }
}
</style>