<template>
    <j-modal
        :title="title"
        :width="width"
        :visible="visible"
        switchFullscreen
        @ok="handleOk"
        :okButtonProps="{ class:{'jee-hidden': disableSubmit} }"
        @cancel="handleCancel"
        cancelText="关闭"
        ok-text="确认">
                <!-- :scroll="{x:true}" -->
            <a-table class="j-table-force-nowrap"
                ref="table"
                size="middle"
                bordered
                :row-key="(record,index) => index"
                :columns="columns"
                :data-source="dataSource"
                :pagination="pagination"
                :loading="loading"
                @change="handlerChange">
                <template slot="resume" slot-scope="text">
                    <a :href="url" style="display:block;" rel="noopener noreferrer" v-for="url in text.split(',')" :key="url">{{url}}</a>
                </template>
                <!-- <span slot="action" slot-scope="text, record">
                    <a href="javascript:void(0);" @click="detailHandler(record)">详情</a>
                    <a-divider type="vertical" />
                </span> -->
            </a-table>
    </j-modal>
</template>

<script>
import {
    baseUrl
} from '@/api/api'
import { GetAtion } from "@/utils/http";
import {
    workTypeUrl,
    workTypeColumns
} from '@/utils/customExpression'
export default {
    // name: 'infoModal',
    data() {
        return {
            title:'',
            width:1200,
            visible: false,
            disableSubmit: false,
            loading:false,
            pagination:{
                pageNo:1,
                pageSize:10,
                total:0,
            },
            model:{
                title:'',
                userId:'',
                userName:'',
                key:''
            },
            dataSource:[],
            columns:[]
        }
    },
    methods:{
        open(record) {
            console.log('=======record',record);
            this.visible = true;
            this.model = Object.assign({},{...record});
            this.columns = workTypeColumns[record.key];
            this.initData();
        },
        //初始化
        initData() {
            let url = baseUrl + workTypeUrl[this.model.key];
            // return
            this.loading = true;
            GetAtion(url,{
                pageNo:this.pagination.pageNo,
                pageSize:this.pagination.pageSize,
                userId:this.model.userId,
                userName:this.model.userName
            }).then(res => {
                this.loading = false;
                if(res.success) {
                    console.log('=========外联返回数据',res.result);
                    this.dataSource = res.result.records;
                    this.pagination.total = res.result.total;
                }else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    })
                }
            })
        },
        handlerChange(pagination){
            this.pagination.pageNo = pagination.current;
            this.pagination.pageSize = pagination.pageSize;
            this.initData();
        },
        handleCancel() {
            this.visible = false;
            this.pagination = {
                pageNo:1,
                pageSize:10,
                total:0
            };
            this.model = {};
        },
        handleOk() {

        },
    }
}
</script>

<style lang="less" scoped>
/** [表格主题样式一] 表格强制列不换行 */
/deep/.j-table-force-nowrap {
    td,th {
        white-space: nowrap;
    }

    .ant-table-selection-column {
        padding: 12px 22px !important;
    }

    /** 列自适应，弊端会导致列宽失效 */
    &.ant-table-wrapper .ant-table-content {
        overflow-x: auto;
    }
}
</style>