<template>
    <a-spin tip="Loading..." :spinning="spinning" class="detail_list">
        <header class="seal_heard">
            <span class="title">{{model.title}}</span>
        </header>
        <div class="main">
            <a-table class="j-table-force-nowrap"
                :scroll="{x:true }"
                ref="table"
                size="middle"
                bordered
                :row-key="(record,index) => index"
                :columns="columns"
                :data-source="dataSource"
                :pagination="pagination"
                :loading="loading"
                @change="handlerChange">
                <span slot="action" slot-scope="text, record">
                    <a href="javascript:void(0);" @click="detailHandler(record)">详情</a>
                    <a-divider type="vertical" />
                </span>
            </a-table>
        </div>
            <!-- 详情抽屉 -->
            <a-drawer
                width="60%"
                title="详情"
                :placement="'left'"
                :closable="false"
                :visible="visible"
                @close="onClose">
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                    <p>Some contents...</p>
            </a-drawer>

    </a-spin>
</template>

<script>
import {
    baseUrl
} from '@/api/api'
import { GetAtion } from "@/utils/http";
import {
    workTypeUrl,
    workTypeColumns
} from '@/utils/customExpression'
export default {
    data(){
        return {
            spinning:false,
            loading:false,
            visible:false,
            pagination:{
                pageNo:1,
                pageSize:30,
                total:0,
            },
            model:{
                title:'',
                userId:'',
                userName:'',
                key:''
            },
            dataSource:[],
            columns:[]
        }
    },
    computed:{
        reQuery() {
            return this.$route.query
        }
    },
    watch:{
        'reQuery':{
            handler(val) {
                console.log('=======',val)
                if(Object.values(val).length) {
                    this.model = Object.assign({},{...val});
                    this.columns = workTypeColumns[val.key];
                    this.initData();
                }
            },
            immediate:true
        },
        
    },
    methods:{
        //初始化
        initData() {
            let url = baseUrl + workTypeUrl[this.model.key];
            this.dataSource = []
            return
            this.loading = true;
            GetAtion(url,{
                pageNo:this.pagination.pageNo,
                pageSize:this.pagination.pageSize,
                userId:this.model.userId || 'ad3d0127-b3c3-4a14-919a-0b728f5481d1',
                userName:this.model.username || '黄兴'
            }).then(res => {
                this.loading = false;
                if(res.success) {
                    console.log('=========外联返回数据',res.result);
                    this.dataSource = res.result.records;
                    this.pagination.total = res.result.total;
                }else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    })
                }
            })
        },
        handlerChange(pagination){
            this.pagination.pageNo = pagination.current;
            this.pagination.pageSize = pagination.pageSize;
            this.initData();
        },
        //详情
        detailHandler(record) {
            this.visible = true;
        },
        onClose() {
            this.visible = false; 
        }
    }
}
</script>

<style scoped lang="less">
.seal_heard{
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #002b45;
    color: #fff;
    font-size: 14px;
    text-align: center;
    // .title{flex:1;text-align: center;}
  }
  .main{padding: 20px;}

  
/** [表格主题样式一] 表格强制列不换行 */
/deep/.j-table-force-nowrap {
  td, th {
    white-space: nowrap;
  }

  .ant-table-selection-column {
    padding: 12px 22px !important;
  }

  /** 列自适应，弊端会导致列宽失效 */
  &.ant-table-wrapper .ant-table-content {
    overflow-x: auto;
  }
}
</style>