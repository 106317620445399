/****贷款类型数据字典 */
export const loanType = {
    type: {
        0:'管理费',
        1:'投标保证金',
        2:'经营借款',
        9:'其他费用'
    },
    typeArr: [
        {text: '管理费', value: 0},
        {text: '投标保证金', value: 1},
        {text: '经营借款', value: 2},
        {text: '其他费用', value: 9}
    ]
}


/****人资平台----晋升工作清单 */
export const workType = {
    // 一阶段
    'msgCount':'短信量',
    'joiningCompany':'签约加盟公司数量',
    'visitLeaderCount':'拜访领导数量',
    'contractMoney':'签约项目额',
    // 二阶段
    'visitPeercount':'拜访同行数量',
    'recruitment':'人才招聘数量',
    'businessSpending':'商务开支',
    // 三阶段
    'reinBureau':'每月拜访领导数量',//拜访10个县镇+2个县区局/6次
    // 'contractMoneyOne':'签约项目破0(一手项目)',
    // 四阶段
    // 'recruitment':'招聘5-10名当地经营人员(个)',
    'contractMoneyOne':'签约项目合同额(一手项目)',//300万(一手项目-首辅合约)
    'recruitInterflow5':'交流同行数量(5年以上经验)/月',
    'recruitInterflow10':'交流同行数量(10年以上经验)/月',
    'recruitInterflow15':'交流同行数量(15年以上经验)/月',
    // 五阶段
    // 'visitLeaderCount':'驾驭10个局的资源(个)',//reinBureau之前的字段
    'breakEvery':'每个子公司合同总额',//突破300万(万)
    'breakLeaderCompany':'分管子公司的数量'//逐步突破5、10、20个子公司的分管(个)
}

/*****
 * 工单详情的
 * url地址
 * ******* */
export const workTypeUrl = {
    breakLeaderCompany:'hr-server/hr/promote/task/find/branch/company',
    breakEvery:'hr-server/hr/promote/task/find/break/every',
    businessSpending:'hr-server/hr/promote/task/find/business/spending',
    contractMoney:'hr-server/hr/promote/task/find/contract/money',
    contractMoneyOne:'hr-server/hr/promote/task/find/contract/money/one',
    joiningCompany:'hr-server/hr/promote/task/find/joining/company',
    msgCount:'hr-server/hr/promote/task/find/msg',
    recruitInterflow5:'hr-server/hr/promote/task/find/recruit/inter/flow5',
    recruitInterflow10:'hr-server/hr/promote/task/find/recruit/inter/flow10',
    recruitInterflow15:'hr-server/hr/promote/task/find/recruit/inter/flow15',
    recruitment:'hr-server/hr/promote/task/find/recruitment',
    reinBureau:'hr-server/hr/promote/task/find/rein/bureau',
    visitLeaderCount:'hr-server/hr/promote/task/find/visit/leader',
    visitPeercount:'hr-server/hr/promote/task/find/visit/peer'
}
/*****
 * 工单详情的
 * columns表头
 * ******* */
 export const workTypeColumns = {
    breakLeaderCompany:[
        {title:'分子公司名称',align:"center",dataIndex: 'name'},
        {title:'下级公司名称',align:"center",dataIndex: 'companyNameName'},
        {title:'合同额(万)',align:"center",dataIndex: 'thisContractRMBAmount'},
        // {title: '操作',dataIndex: 'action',align:"center",width:100,scopedSlots: { customRender: 'action' }}
    ],
    breakEvery:[
        // {title:'分子公司名称',align:"center",dataIndex: 'name'},
        {title:'下级公司名称',align:"center",dataIndex: 'companyNameName'},
        {title:'合同额(万)',align:"center",dataIndex: 'thisContractRMBAmount'},
        // {title: '操作',dataIndex: 'action',align:"center",width:100,scopedSlots: { customRender: 'action' }}
    ],
    businessSpending:[
        {title:'申请日期',align:"center",dataIndex: 'applyDate'},
        {title:'申请人',align:"center",dataIndex: 'applyUserName'},
        {title:'申请人部门',align:"center",dataIndex: 'applyDeptName'},
        {title:'相关责任部门',align:"center",dataIndex: 'deptInfoName'},
        {title:'实际支付金额',align:"center",dataIndex: 'factPaymentVal'},
        {title:'报销形式',align:"center",dataIndex: 'reimbursement'},
        {title:'报销金额总计',align:"center",dataIndex: 'reimbursementV'},
        {title:'报销单编号',align:"center",dataIndex: 'serialNumber'},
        // {title: '操作',dataIndex: 'action',align:"center",width:100,scopedSlots: { customRender: 'action' }}
    ],
    contractMoney:[
        {title:'商务负责人名称',align:"center",dataIndex: 'businessManagerIdname'},
        {title:'合同性质',align:"center",dataIndex: 'contractClass'},
        {title:'合同类型',align:"center",dataIndex: 'contractType'},
        {title:'主设计师名称',align:"center",dataIndex: 'mainDesignUserName'},
        {title:'合同名称',align:"center",dataIndex: 'name'},
        {title:'甲方',align:"center",dataIndex: 'partyAname'},
        {title:'乙方',align:"center",dataIndex: 'partyBname'},
        {title:'项目负责人',align:"center",dataIndex: 'projectChargeUserName'},
        {title:'合同编号',align:"center",dataIndex: 'serialNumber'},
        {title:'签约日期',align:"center",dataIndex: 'signDate'},
        {title:'本合同人民币金额',align:"center",dataIndex: 'thisContractRmbamount'},
        // {title: '操作',dataIndex: 'action',align:"center",width:100,scopedSlots: { customRender: 'action' }}
    ],
    contractMoneyOne:[
        {title:'商务负责人名称',align:"center",dataIndex: 'businessManagerIdname'},
        {title:'合同性质',align:"center",dataIndex: 'contractClass'},
        {title:'合同类型',align:"center",dataIndex: 'contractType'},
        {title:'主设计师名称',align:"center",dataIndex: 'mainDesignUserName'},
        {title:'合同名称',align:"center",dataIndex: 'name'},
        {title:'甲方',align:"center",dataIndex: 'partyAname'},
        {title:'乙方',align:"center",dataIndex: 'partyBname'},
        {title:'项目负责人',align:"center",dataIndex: 'projectChargeUserName'},
        {title:'合同编号',align:"center",dataIndex: 'serialNumber'},
        {title:'签约日期',align:"center",dataIndex: 'signDate'},
        {title:'本合同人民币金额',align:"center",dataIndex: 'thisContractRmbamount'},
        // {title: '操作',dataIndex: 'action',align:"center",width:100,scopedSlots: { customRender: 'action' }}
    ],
    joiningCompany:[
        {title:'最新年报地址',align:"center",dataIndex: 'annualAddress'},
        {title:'核准时间',align:"center",dataIndex: 'approvalTime'},
        {title:'省市区县',align:"center",dataIndex: 'province',customRender: (t, r, index) => r.province+r.city+r.area},
        {title:'经营范围',align:"center",dataIndex: 'businessScope'},
        {title:'公司名称',align:"center",dataIndex: 'companyName'},
        {title:'公司类型',align:"center",dataIndex: 'companyType'},
        {title:'统一社会信用代码',align:"center",dataIndex: 'creditCode'},
        // {title:'是否已删除',align:"center",dataIndex: 'deleted'},
        {title:'邮箱',align:"center",dataIndex: 'email'},
        {title:'成立时间',align:"center",dataIndex: 'foundedTime'},
        {title:'纳税人识别号',align:"center",dataIndex: 'identificationNum'},
        {title:'是否导入',align:"center",dataIndex: 'importFlag',customRender: (t, r, index) => r.importFlag==1?'导入':'录入'},
        {title:'所属行业',align:"center",dataIndex: 'industry'},
        {title:'参保人数',align:"center",dataIndex: 'insuredPersonNum'},
        {title:'意向',align:"center",dataIndex: 'intent',customRender: (t, r, index) => r.intent==0?'暂无意向':r.intent==1?'意向一般':r.intent==2?'意向较大':'已签约'},
        {title:'法人代表',align:"center",dataIndex: 'legalPerson'},
        {title:'经营状态',align:"center",dataIndex: 'operationStatus',customRender: (t, r, index) => r.operationStatus==0?'注销':r.operationStatus==1?'续存':r.operationStatus==2?'吊销':'未注销'},
        {title:'组织机构代码',align:"center",dataIndex: 'orgCode'},
        {title:'其他邮箱',align:"center",dataIndex: 'otherMail'},
        {title:'其他电话',align:"center",dataIndex: 'otherTel'},
        {title:'实缴资金',align:"center",dataIndex: 'paidCapital'},
        {title:'计划拜访时间',align:"center",dataIndex: 'planVisitTime'},
        // {title:'所属省份',align:"center",dataIndex: 'province'},
        {title:'注册地址',align:"center",dataIndex: 'registeredAddress'},
        {title:'注册资本',align:"center",dataIndex: 'registeredCapital'},
        {title:'注册号',align:"center",dataIndex: 'registeredNo'},
        {title:'状态',align:"center",dataIndex: 'status',customRender: (t, r, index) => r.status==0?'未拜访':r.status==1?'拜访中':r.status==2?'拜访结束':'已签约'},
        {title:'电话',align:"center",dataIndex: 'tel'},
        {title:'曾用名',align:"center",dataIndex: 'usedName'},
        {title:'网址',align:"center",dataIndex: 'webSite'},
        // {title: '操作',dataIndex: 'action',align:"center",width:100,scopedSlots: { customRender: 'action' }}
    ],
    msgCount:[
        {title:'加盟政策',align:"center",dataIndex: 'alliancePolicy'},
        // {title:'打卡地址',align:"center",dataIndex: 'clickAddresss'},
        {title:'联系电话/微信号',align:"center",dataIndex: 'contactNum'},
        {title:'工作内容',align:"center",dataIndex: 'content'},
        {title:'对接人',align:"center",dataIndex: 'dockingPeople'},
        {title:'意向',align:"center",dataIndex: 'intent',customRender: (t, r, index) => r.intent==0?'暂无意向':r.intent==1?'意向一般':r.intent==2?'意向较大':'已签约'},
        // {title:'实际营业地址与工商登记地址是否一致',align:"center",dataIndex: 'isConsistent'},
        // {title:'维度',align:"center",dataIndex: 'latitude'},
        // {title:'经度',align:"center",dataIndex: 'longitude'},
        // {title:'主要行业',align:"center",dataIndex: 'majorIndustry'},
        {title:'信息数',align:"center",dataIndex: 'msgCount'},
        {title:'其他合作方向',align:"center",dataIndex: 'otherCooperation'},
        // {title:'同行人',align:"center",dataIndex: 'partner'},
        {title:'公司资质',align:"center",dataIndex: 'qualification'},
        {title:'实际营业地址',align:"center",dataIndex: 'realAddress'},
        {title:'是否有注册师',align:"center",dataIndex: 'registFlag',customRender: (t, r, index) => r.registFlag==0?'没有':'有'},
        {title:'注册师名称和人数',align:"center",dataIndex: 'registInfo'},
        {title:'状态',align:"center",dataIndex: 'status',customRender: (t, r, index) => r.status==0?'未拜访':r.status==1?'拜访中':r.status==2?'拜访结束':r.status==3?'已签约':'已改派'},
        {title:'团队规模',align:"center",dataIndex: 'team'},
        {title:'电话数',align:"center",dataIndex: 'telCount'},
        {title:'拜访时间',align:"center",dataIndex: 'visitTime'},
        {title:'拜访类型',align:"center",dataIndex: 'visitType',customRender: (t, r, index) =>r.visitType==1?'线上拜访':r.visitType==2?'线下拜访':''},
        // {title:'拜访人',align:"center",dataIndex: 'visitUser'},
        // {title: '操作',dataIndex: 'action',align:"center",width:100,scopedSlots: { customRender: 'action' }}
    ],
    recruitInterflow5:[
        {title:'姓名',align:"center",dataIndex: 'name'},
        {title:'年龄',align:"center",dataIndex: 'age'},
        {title:'电话',align:"center",dataIndex: 'phone'},
        {title:'简历',align:"center",dataIndex: 'resume',scopedSlots: { customRender: 'resume' }},
        {title:'应聘岗位',align:"center",dataIndex: 'typeForJob'},
        {title:'工作年限',align:"center",dataIndex: 'workNumber'},
        {title:'微信号',align:"center",dataIndex: 'wxNumber'},
        // {title: '操作',dataIndex: 'action',align:"center",width:100,scopedSlots: { customRender: 'action' }}
    ],
    recruitInterflow10:[
        {title:'姓名',align:"center",dataIndex: 'name'},
        {title:'年龄',align:"center",dataIndex: 'age'},
        {title:'电话',align:"center",dataIndex: 'phone'},
        {title:'简历',align:"center",dataIndex: 'resume',scopedSlots: { customRender: 'resume' }},
        {title:'应聘岗位',align:"center",dataIndex: 'typeForJob'},
        {title:'工作年限',align:"center",dataIndex: 'workNumber'},
        {title:'微信号',align:"center",dataIndex: 'wxNumber'},
        // {title: '操作',dataIndex: 'action',align:"center",width:100,scopedSlots: { customRender: 'action' }}
    ],
    recruitInterflow15:[
        {title:'姓名',align:"center",dataIndex: 'name'},
        {title:'年龄',align:"center",dataIndex: 'age'},
        {title:'电话',align:"center",dataIndex: 'phone'},
        {title:'简历',align:"center",dataIndex: 'resume',scopedSlots: { customRender: 'resume' }},
        {title:'应聘岗位',align:"center",dataIndex: 'typeForJob'},
        {title:'工作年限',align:"center",dataIndex: 'workNumber'},
        {title:'微信号',align:"center",dataIndex: 'wxNumber'},
        // {title: '操作',dataIndex: 'action',align:"center",width:100,scopedSlots: { customRender: 'action' }}
    ],
    recruitment:[
        {title:'被招聘人',align:"center",dataIndex: 'byUsername'},
        {title:'所属部门',align:"center",dataIndex: 'deptName'},
        {title:'招聘人',align:"center",dataIndex: 'username'},
        // {title: '操作',dataIndex: 'action',align:"center",width:100,scopedSlots: { customRender: 'action' }}
    ],
    reinBureau:[
        {title:'甲方',align:"center",dataIndex: 'visitTarget'},
        {title:'开始日期',align:"center",dataIndex: 'planStartDate'},
        {title:'截止日期',align:"center",dataIndex: 'planEndDate'},
        {title:'创建人',align:"center",dataIndex: 'createUser'},
        {title:'填报人',align:"center",dataIndex: 'writeUserName'},
        {title:'填报部门',align:"center",dataIndex: 'writeDeptName'},
        // {title: '操作',dataIndex: 'action',align:"center",width:100,scopedSlots: { customRender: 'action' }}
    ],
    visitLeaderCount:[
        {title:'甲方',align:"center",dataIndex: 'visitTarget'},
        {title:'开始日期',align:"center",dataIndex: 'planStartDate'},
        {title:'截止日期',align:"center",dataIndex: 'planEndDate'},
        {title:'创建人',align:"center",dataIndex: 'createUser'},
        {title:'填报人',align:"center",dataIndex: 'writeUserName'},
        {title:'填报部门',align:"center",dataIndex: 'writeDeptName'},
        // {title: '操作',dataIndex: 'action',align:"center",width:100,scopedSlots: { customRender: 'action' }}
    ],
    visitPeercount:[
        {title:'加盟政策',align:"center",dataIndex: 'alliancePolicy',ellipsis:true,width:100},
        {title:'打卡地址',align:"center",dataIndex: 'clickAddresss',ellipsis:true,width:100},
        {title:'联系电话/微信号',align:"center",dataIndex: 'contactNum',ellipsis:true,width:130},
        {title:'工作内容',align:"center",dataIndex: 'content',ellipsis:true,width:200},
        {title:'对接人',align:"center",dataIndex: 'dockingPeople',ellipsis:true,width:100},
        {title:'意向',align:"center",dataIndex: 'intent',ellipsis:true,width:100,customRender: (t, r, index) => r.intent==0?'暂无意向':r.intent==1?'意向一般':r.intent==2?'意向较大':'已签约'},
        {title:'营业&工商地址是否一致',align:"center",dataIndex: 'isConsistent',ellipsis:true,width:120,customRender: (t, r, index) => r.isConsistent==1?'一致':'不一致'},
        // 实际营业地址与工商登记地址是否一致
        // {title:'维度',align:"center",dataIndex: 'latitude'},
        // {title:'经度',align:"center",dataIndex: 'longitude'},
        {title:'主要行业',align:"center",dataIndex: 'majorIndustry',ellipsis:true,width:100},
        {title:'信息数',align:"center",dataIndex: 'msgCount',ellipsis:true,width:100},
        {title:'其他合作方向',align:"center",dataIndex: 'otherCooperation',ellipsis:true,width:120},
        {title:'同行人',align:"center",dataIndex: 'partner',ellipsis:true,width:100},
        {title:'公司资质',align:"center",dataIndex: 'qualification',ellipsis:true,width:100},
        {title:'实际营业地址',align:"center",dataIndex: 'realAddress',ellipsis:true,width:120},
        {title:'是否有注册师',align:"center",dataIndex: 'registFlag',ellipsis:true,width:120,customRender: (t, r, index) => r.registFlag==0?'没有':'有'},
        {title:'注册师人数',align:"center",dataIndex: 'registInfo',ellipsis:true,width:100},//名称和
        {title:'状态',align:"center",dataIndex: 'status',ellipsis:true,width:100,customRender: (t, r, index) => r.status==0?'未拜访':r.status==1?'拜访中':r.status==2?'拜访结束':r.status==3?'已签约':'已改派'},
        {title:'团队规模',align:"center",dataIndex: 'team',ellipsis:true,width:100},
        {title:'电话数',align:"center",dataIndex: 'telCount',ellipsis:true,width:100},
        {title:'拜访时间',align:"center",dataIndex: 'visitTime',ellipsis:true,width:100},
        {title:'拜访类型',align:"center",dataIndex: 'visitType',ellipsis:true,width:100,customRender: (t, r, index) =>r.visitType==1?'线上拜访':r.visitType==2?'线下拜访':''},
        {title:'拜访人',align:"center",dataIndex: 'visitUser',ellipsis:true,width:100},
        // {title: '操作',dataIndex: 'action',align:"center",width:100,scopedSlots: { customRender: 'action' }}
    ]
}


/*********数据字典 */
/****业务类型 */
export const business_type = {
  1:'建筑设计',
  2:'市政设计',
  3:'EPC项目',
  4:'道桥行业',
  5:'电力行业',
  6:'勘察行业',
  7:'咨询行业',
  8:'环境行业',
  9:'水利行业',
  10:'景观行业',
  11:'农业行业',
  12:'排水行业',
  13:'建材行业',
  14:'化工行业',
  15:'垃圾处理工程行业',
  16:'鉴定',
  17:'检测行业',
  18:'规划行业',
  19:'监理行业',
  20:'测绘行业',
  21:'医药行业',
  22:'石油行业',
  23:'桥梁设计',
  24:'园林行业',
  25:'公路行业',
  26:'造价行业',
  27:'冶金行业',
  28:'压力管道',
  29:'入库',
}

//合同类型
export const contract_type = {
    'QT':'其他',
    'SJ':'设计',
    'KC':'勘察',
    'CH':'测绘',
    'ZX':'咨询',
    'JL':'监理',
    'SG':'施工',
    'JD':'鉴定',
    'JC':'检测',
}