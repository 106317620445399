<template>
  <!-- <a-card :bordered="false" class="promote_echart"> -->
  <a-spin tip="Loading..." :spinning="spinning" class="promote_echart">
    <header class="seal_heard">
        <span class="back">
          <a-icon type="border" :style="{ fontSize: '16px', color: 'white' , backgroundColor: '#40a9ff'}"/>所处职位
          <a-icon type="border" :style="{ fontSize: '16px', color: 'white' , backgroundColor: 'green'}"/>已完成
          <a-icon type="border" :style="{ fontSize: '16px', color: '#fad714', backgroundColor: '#ffa533' }"/>未完成
          <a-icon type="border" :style="{ fontSize: '16px', color: '#ccf3f3', backgroundColor: '#b1f7f7' }"/>提前做
          <a-icon type="border" :style="{ fontSize: '16px', color: '#bae7ff', backgroundColor: '#bae7ff' }"/>参考工单
        </span>
        <span class="title">工作清单总览图</span>
        <div class="btn">
          <a-button type="primary" @click="handlerRecruit">招聘人才库</a-button>
          <a-button type="primary" @click="handlerTalent">人才交流库</a-button>
        </div>
    </header>
    <div class="main">
      <a-steps ref="steps">
        <!-- testData 测试 -->
        <a-step status="finish"
          :class="{'cur_item':item.gidFlag}"
          v-for="item in promoteChartData" :key="item.gid"
          :title="item.description" :subTitle="'('+item.stageName+')'">
          <a-icon slot="icon" type="user"/>
          <template slot="description">
            <div ref="chain" :style="{'min-height':chainH+'px'}">
              <h4>工作汇总{{(item.gid == 1 || item.gid == 2)?'(累积量)':'(当月任务)'}}，如下：</h4>
              <span v-if="!item.workList || item.workList.length==0">暂无数据</span>
              <div v-for="work in item.workList" :key="work.branch" class="chain_item">
                <template v-for="(type,key) of reWorkType">
                  <span :key="key" v-if="work[key]">
                    {{key == 'breakEvery' ? '子公司合同额突破300万的数量':type}}：<a @click="openChain(key,type)">{{work[key]}}</a>
                    {{(key=='businessSpending' || key=='contractMoney'|| key=='contractMoneyOne') ? '万':''}}
                  </span>
                </template>
              </div>
            </div>

            <a-collapse :bordered="false"  v-model="activeKey" ref="step" :data-gid='item.gid'>
              <template #expandIcon="props">
                <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
              </template>
              <a-collapse-panel v-for="(el,index) in item.hrPromoteLevelVOS" :key="el.id+''"
                :class="[el.sortFlag?'isSel':'']">
                <template slot="header">
                  <div class="hover_active">
                    <span>{{el.target}}--任务清单&nbsp;&nbsp;[参考工资:{{el.salary}}元]</span>
                  </div>
                </template>
                <div :style="{'min-height':elH[index] + 'px'}">
                  <div class="item_box">
                    
                  <!-- 参考清单 -->
                  <div :class="['sort_list',(item.gid!=1 && item.gid!=2)?'sort_list_column':'']">
                    <div class="sort_list_step" v-for="it in el.list" :key="it.id">
                      <template v-for="(type,key) of reWorkType">
                        <span class="list_step_sort"
                          v-if="it[key]" :key="key">
                          <span class="tip-icon">
                            *{{type}}：{{it[key]}}
                            {{(key=='businessSpending' || key=='breakEvery') ? '万'
                            : key=='contractMoney'?'元'
                            : key=='contractMoneyOne'&&it[key]==1 ?'元'
                            : key=='contractMoneyOne'?'万':''}}
                          </span>
                          <span class="tip_money">
                            工资{{it.salary}}
                            <a-icon class="step-icon" type="swap-right" v-if="item.gid==1 || item.gid==2"/>
                          </span>
                        </span>
                      </template>
                    </div>
                  </div>
                  <!-- 当前用户做的清单 -->
                  <div :class="['sort_list',(item.gid!=1 && item.gid!=2)?'sort_list_column':'']" v-if="el.curList">
                    <div :class="['sort_list_step',it.flag=='1'?'sort_list_done':it.flag=='2'?'sort_list_doing':'sort_list_mo']"
                      v-for="it in el.curList" :key="it.id*7">
                      <template v-for="(type,key) of reWorkType">
                        <span class="list_step_sort"
                          v-if="it[key]" :key="key">
                          <span class="tip-icon">
                            *{{key == 'breakEvery' ? '子公司合同额突破300万的数量':type}} ：{{it[key]}}
                            {{(key=='businessSpending' || key=='contractMoney' || key=='contractMoneyOne') ? '万':''}}
                          </span>
                          <span class="tip_money">
                            工资{{it.salary}}
                            <a-icon class="step-icon" type="swap-right" v-if="item.gid==1 || item.gid==2"/>
                          </span>
                        </span>
                      </template>
                    </div>
                  </div>
                  
                  </div>
                </div>
              </a-collapse-panel>
            </a-collapse>

          </template>
        </a-step>
      </a-steps>
    </div>
    <!-- 弹窗 -->
    <pool-model ref="modelForm" @subOk="subOk"></pool-model>
    <info-model ref="infoForm"></info-model>
  </a-spin>
</template>

<script>
  import {
    getPromoteChart,
    getCurrentUserChart
  } from '@/api/api'
  import {
    workType
  } from '@/utils/customExpression'
import PoolModel from './modules/PoolModel'
import InfoModel from './modules/InfoModel' 

  export default {
    name: 'HrPromoteLevelCHart',
    mixins:[],
    components: {
      PoolModel,
      InfoModel
    },
    data () {
      return {
        description: '用户晋升阶段层级图',
        spinning:false,
        //晋升图
        promoteChartData:[],
        //当前登录人的工单
        promoteChart:[],
        //元素的高度
        elH:[],
        chainH:10,
        //默认打开的项的key
        activeKey:[]
      }
    },
    created() {
      return
      this.promoteChartData = [
  {
    "gid": "1",
    "stageName": "第一阶段",
    "description": "职员",
    workList:[
      {
            "id": 1,
            "roleName": "秘书处职员",
            "roleId": "1",
            "gid": "1",
            "target": "同行",
            "branch": "1",
            "sort": 1,
            "isend": 0,
            "salary": "5000",
            "msgCount": 1500,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-20",
            "createTime": "2021-12-09",
            "isShow": true
          },{
            "id": 4,
            "roleName": "秘书处职员",
            "roleId": "1",
            "gid": "1",
            "target": "同行",
            "branch": "2",
            "sort": 1,
            "isend": 1,
            "salary": "6000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 1,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-20",
            "createTime": "2021-12-09",
            "isShow": true
          }
    ],
    "hrPromoteLevelVOS": [
      {
        "id": 1,
        "roleName": "秘书处职员",
        "roleId": "1",
        "gid": "1",
        "target": "同行",
        "branch": "1",
        "sort": 1,
        "sortFlag": true,
        "isend": 0,
        "salary": "5000",
        "msgCount": 2000,
        "visitPeercount": 0,
        "visitLeaderCount": 0,
        "joiningCompany": 0,
        "recruitment": 0,
        "contractMoney": 0,
        "contractMoneyOne": 0,
        "businessSpending": 0,
        "recruitInterflow5": 0,
        "recruitInterflow10": 0,
        "recruitInterflow15": 0,
        "reinBureau": 0,
        "breakEvery": 0,
        "breakLeaderCompany": 0,
        "delFlag": 0,
        "updateTime": "2021-12-20",
        "createTime": "2021-12-09",
        "isShow": true,
        "list": [
          {
            "id": 1,
            "roleName": "秘书处职员",
            "roleId": "1",
            "gid": "1",
            "target": "同行",
            "branch": "1",
            "sort": 1,
            "isend": 0,
            "salary": "5000",
            "msgCount": 500,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-20",
            "createTime": "2021-12-09",
            "isShow": true
          },
          {
            "id": 2,
            "roleName": "秘书处职员",
            "roleId": "1",
            "gid": "1",
            "target": "同行",
            "branch": "1",
            "sort": 2,
            "isend": 0,
            "salary": "5500",
            "msgCount": 1000,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-20",
            "createTime": "2021-12-09",
            "isShow": true
          },
          {
            "id": 16,
            "roleName": "秘书处职员",
            "roleId": "1",
            "gid": "1",
            "target": "同行",
            "branch": "1",
            "sort": 3,
            "isend": 1,
            "salary": "6000",
            "msgCount": 2000,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-20",
            "createTime": "2021-12-14",
            "isShow": true
          }
        ],
        curList:[
          {
            "id": 1,
            "roleName": "秘书处职员",
            "roleId": "1",
            "gid": "1",
            "target": "同行",
            "branch": "1",
            "sort": 1,
            "isend": 0,
            "salary": "5000",
            "msgCount": 500,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-20",
            "createTime": "2021-12-09",
            "isShow": true
          },
        ]
      },
      {
        "id": 4,
        "roleName": "秘书处职员",
        "roleId": "1",
        "gid": "1",
        "target": "同行",
        "branch": "2",
        "sort": 1,
        "isend": 1,
        "salary": "6000",
        "msgCount": 0,
        "visitPeercount": 0,
        "visitLeaderCount": 0,
        "joiningCompany": 1,
        "recruitment": 0,
        "contractMoney": 0,
        "contractMoneyOne": 0,
        "businessSpending": 0,
        "recruitInterflow5": 0,
        "recruitInterflow10": 0,
        "recruitInterflow15": 0,
        "reinBureau": 0,
        "breakEvery": 0,
        "breakLeaderCompany": 0,
        "delFlag": 0,
        "updateTime": "2021-12-20",
        "createTime": "2021-12-09",
        "isShow": true,
        "list": [
          {
            "id": 4,
            "roleName": "秘书处职员",
            "roleId": "1",
            "gid": "1",
            "target": "同行",
            "branch": "2",
            "sort": 1,
            "isend": 1,
            "salary": "6000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 1,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-20",
            "createTime": "2021-12-09",
            "isShow": true
          }
        ]
      },
      {
        "id": 11,
        "roleName": "经营普通职员",
        "roleId": "2",
        "gid": "1",
        "target": "甲方",
        "branch": "1",
        "sort": 1,
        "isend": 0,
        "salary": "5000",
        "msgCount": 0,
        "visitPeercount": 0,
        "visitLeaderCount": 25,
        "joiningCompany": 0,
        "recruitment": 0,
        "contractMoney": 0,
        "contractMoneyOne": 0,
        "businessSpending": 0,
        "recruitInterflow5": 0,
        "recruitInterflow10": 0,
        "recruitInterflow15": 0,
        "reinBureau": 0,
        "breakEvery": 0,
        "breakLeaderCompany": 0,
        "delFlag": 0,
        "updateTime": "2021-12-20",
        "createTime": "2021-12-10",
        "isShow": true,
        "list": [
          {
            "id": 11,
            "roleName": "经营普通职员",
            "roleId": "2",
            "gid": "1",
            "target": "甲方",
            "branch": "1",
            "sort": 1,
            "isend": 0,
            "salary": "5000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 5,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-20",
            "createTime": "2021-12-10",
            "isShow": true
          },
          {
            "id": 17,
            "roleName": "经营普通职员",
            "roleId": "2",
            "gid": "1",
            "target": "甲方",
            "branch": "1",
            "sort": 2,
            "isend": 0,
            "salary": "5500",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 15,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-20",
            "createTime": "2021-12-20",
            "isShow": true
          },
          {
            "id": 18,
            "roleName": "经营普通职员",
            "roleId": "2",
            "gid": "1",
            "target": "同行",
            "branch": "1",
            "sort": 3,
            "isend": 1,
            "salary": "6000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 25,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-20",
            "createTime": "2021-12-20",
            "isShow": true
          }
        ]
      },
      {
        "id": 3,
        "roleName": "经营普通职员",
        "roleId": "2",
        "gid": "1",
        "target": "同行",
        "branch": "2",
        "sort": 1,
        "isend": 1,
        "salary": "6000",
        "msgCount": 0,
        "visitPeercount": 0,
        "visitLeaderCount": 0,
        "joiningCompany": 0,
        "recruitment": 0,
        "contractMoney": 1,
        "contractMoneyOne": 0,
        "businessSpending": 0,
        "recruitInterflow5": 0,
        "recruitInterflow10": 0,
        "recruitInterflow15": 0,
        "reinBureau": 0,
        "breakEvery": 0,
        "breakLeaderCompany": 0,
        "delFlag": 0,
        "updateTime": "2021-12-20",
        "createTime": "2021-12-09",
        "isShow": true,
        "list": [
          {
            "id": 3,
            "roleName": "经营普通职员",
            "roleId": "2",
            "gid": "1",
            "target": "同行",
            "branch": "2",
            "sort": 1,
            "isend": 1,
            "salary": "6000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 1,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-20",
            "createTime": "2021-12-09",
            "isShow": true
          }
        ]
      }
    ]
  },
  {
    "gid": "2",
    "stageName": "第二阶段",
    "description": "职员",
    workList:[
      {
            "id": 5,
            "roleName": "秘书处职员",
            "roleId": "1",
            "gid": "2",
            "target": "同行",
            "branch": "1",
            "sort": 1,
            "isend": 0,
            "salary": "7000",
            "msgCount": 0,
            "visitPeercount": 200,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-10",
            "createTime": "2021-12-10",
            "isShow": true
          },
    ],
    "hrPromoteLevelVOS": [
      {
        "id": 5,
        "roleName": "秘书处职员",
        "roleId": "1",
        "gid": "2",
        "target": "同行",
        "branch": "1",
        "sort": 1,
        "isend": 0,
        "salary": "7000",
        "msgCount": 0,
        "visitPeercount": 200,
        "visitLeaderCount": 0,
        "joiningCompany": 0,
        "recruitment": 3,
        "contractMoney": 0,
        "contractMoneyOne": 0,
        "businessSpending": 0,
        "recruitInterflow5": 0,
        "recruitInterflow10": 0,
        "recruitInterflow15": 0,
        "reinBureau": 0,
        "breakEvery": 0,
        "breakLeaderCompany": 0,
        "delFlag": 0,
        "updateTime": "2021-12-10",
        "createTime": "2021-12-10",
        "isShow": true,
        "list": [
          {
            "id": 5,
            "roleName": "秘书处职员",
            "roleId": "1",
            "gid": "2",
            "target": "同行",
            "branch": "1",
            "sort": 1,
            "isend": 0,
            "salary": "7000",
            "msgCount": 0,
            "visitPeercount": 200,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-10",
            "createTime": "2021-12-10",
            "isShow": true
          },
          {
            "id": 6,
            "roleName": "秘书处职员",
            "roleId": "1",
            "gid": "2",
            "target": "同行",
            "branch": "1",
            "sort": 2,
            "isend": 1,
            "salary": "8000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 3,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-10",
            "createTime": "2021-12-10",
            "isShow": true
          }
        ]
      },
      {
        "id": 7,
        "roleName": "秘书处职员",
        "roleId": "1",
        "gid": "2",
        "target": "同行",
        "branch": "2",
        "sort": 1,
        "isend": 1,
        "salary": "8000",
        "msgCount": 0,
        "visitPeercount": 0,
        "visitLeaderCount": 0,
        "joiningCompany": 0,
        "recruitment": 5,
        "contractMoney": 0,
        "contractMoneyOne": 0,
        "businessSpending": 0,
        "recruitInterflow5": 0,
        "recruitInterflow10": 0,
        "recruitInterflow15": 0,
        "reinBureau": 0,
        "breakEvery": 0,
        "breakLeaderCompany": 0,
        "delFlag": 0,
        "updateTime": "2021-12-10",
        "createTime": "2021-12-10",
        "isShow": true,
        "list": [
          {
            "id": 7,
            "roleName": "秘书处职员",
            "roleId": "1",
            "gid": "2",
            "target": "同行",
            "branch": "2",
            "sort": 1,
            "isend": 1,
            "salary": "8000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 5,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-10",
            "createTime": "2021-12-10",
            "isShow": true
          }
        ]
      },
      {
        "id": 19,
        "roleName": "经营普通职员",
        "roleId": "2",
        "gid": "2",
        "target": "甲方",
        "branch": "1",
        "sort": 1,
        "isend": 0,
        "salary": "7000",
        "msgCount": 0,
        "visitPeercount": 0,
        "visitLeaderCount": 0,
        "joiningCompany": 0,
        "recruitment": 0,
        "contractMoney": 0,
        "contractMoneyOne": 0,
        "businessSpending": 30,
        "recruitInterflow5": 0,
        "recruitInterflow10": 0,
        "recruitInterflow15": 0,
        "reinBureau": 0,
        "breakEvery": 0,
        "breakLeaderCompany": 0,
        "delFlag": 0,
        "updateTime": "2021-12-20",
        "createTime": "2021-12-20",
        "isShow": true,
        "list": [
          {
            "id": 19,
            "roleName": "经营普通职员",
            "roleId": "2",
            "gid": "2",
            "target": "甲方",
            "branch": "1",
            "sort": 1,
            "isend": 0,
            "salary": "7000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 10,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-20",
            "createTime": "2021-12-20",
            "isShow": true
          },
          {
            "id": 20,
            "roleName": "经营普通职员",
            "roleId": "2",
            "gid": "2",
            "target": "甲方",
            "branch": "1",
            "sort": 2,
            "isend": 1,
            "salary": "8000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 30,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-20",
            "createTime": "2021-12-20",
            "isShow": true
          }
        ]
      }
    ]
  },
  {
    "gid": "3",
    "stageName": "第三阶段",
    "description": "负责人",
    "hrPromoteLevelVOS": [
      {
        "id": 8,
        "roleName": "秘书处职员",
        "roleId": "1",
        "gid": "3",
        "target": "甲方",
        "branch": "1",
        "sort": 1,
        "isend": 1,
        "salary": "10000",
        "msgCount": 0,
        "visitPeercount": 0,
        "visitLeaderCount": 12,
        "joiningCompany": 0,
        "recruitment": 0,
        "contractMoney": 0,
        "contractMoneyOne": 0,
        "businessSpending": 0,
        "recruitInterflow5": 0,
        "recruitInterflow10": 0,
        "recruitInterflow15": 0,
        "reinBureau": 0,
        "breakEvery": 0,
        "breakLeaderCompany": 0,
        "delFlag": 0,
        "updateTime": "2021-12-20",
        "createTime": "2021-12-10",
        "isShow": true,
        "list": [
          {
            "id": 8,
            "roleName": "秘书处职员",
            "roleId": "1",
            "gid": "3",
            "target": "甲方",
            "branch": "1",
            "sort": 1,
            "isend": 1,
            "salary": "10000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 12,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-20",
            "createTime": "2021-12-10",
            "isShow": true
          }
        ]
      },
      {
        "id": 9,
        "roleName": "秘书处职员",
        "roleId": "1",
        "gid": "3",
        "target": "甲方",
        "branch": "2",
        "sort": 1,
        "isend": 1,
        "salary": "10000",
        "msgCount": 0,
        "visitPeercount": 0,
        "visitLeaderCount": 0,
        "joiningCompany": 0,
        "recruitment": 0,
        "contractMoney": 0,
        "contractMoneyOne": 0.1,
        "businessSpending": 0,
        "recruitInterflow5": 0,
        "recruitInterflow10": 0,
        "recruitInterflow15": 0,
        "reinBureau": 0,
        "breakEvery": 0,
        "breakLeaderCompany": 0,
        "delFlag": 0,
        "updateTime": "2021-12-20",
        "createTime": "2021-12-10",
        "isShow": true,
        "list": [
          {
            "id": 9,
            "roleName": "秘书处职员",
            "roleId": "1",
            "gid": "3",
            "target": "甲方",
            "branch": "2",
            "sort": 1,
            "isend": 1,
            "salary": "10000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0.1,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-20",
            "createTime": "2021-12-10",
            "isShow": true
          }
        ]
      },
      {
        "id": 21,
        "roleName": "经营普通职员",
        "roleId": "2",
        "gid": "3",
        "target": "甲方",
        "branch": "1",
        "sort": 1,
        "isend": 1,
        "salary": "10000",
        "msgCount": 0,
        "visitPeercount": 0,
        "visitLeaderCount": 12,
        "joiningCompany": 0,
        "recruitment": 0,
        "contractMoney": 0,
        "contractMoneyOne": 0,
        "businessSpending": 0,
        "recruitInterflow5": 0,
        "recruitInterflow10": 0,
        "recruitInterflow15": 0,
        "reinBureau": 0,
        "breakEvery": 0,
        "breakLeaderCompany": 0,
        "delFlag": 0,
        "updateTime": "2021-12-20",
        "createTime": "2021-12-20",
        "isShow": true,
        "list": [
          {
            "id": 21,
            "roleName": "经营普通职员",
            "roleId": "2",
            "gid": "3",
            "target": "甲方",
            "branch": "1",
            "sort": 1,
            "isend": 1,
            "salary": "10000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 12,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-20",
            "createTime": "2021-12-20",
            "isShow": true
          }
        ]
      },
      {
        "id": 22,
        "roleName": "经营普通职员",
        "roleId": "2",
        "gid": "3",
        "target": "甲方",
        "branch": "2",
        "sort": 1,
        "isend": 1,
        "salary": "10000",
        "msgCount": 0,
        "visitPeercount": 0,
        "visitLeaderCount": 0,
        "joiningCompany": 0,
        "recruitment": 0,
        "contractMoney": 0,
        "contractMoneyOne": 0.01,
        "businessSpending": 0,
        "recruitInterflow5": 0,
        "recruitInterflow10": 0,
        "recruitInterflow15": 0,
        "reinBureau": 0,
        "breakEvery": 0,
        "breakLeaderCompany": 0,
        "delFlag": 0,
        "updateTime": "2021-12-21",
        "createTime": "2021-12-21",
        "isShow": true,
        "list": [
          {
            "id": 22,
            "roleName": "经营普通职员",
            "roleId": "2",
            "gid": "3",
            "target": "甲方",
            "branch": "2",
            "sort": 1,
            "isend": 1,
            "salary": "10000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0.01,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-21",
            "createTime": "2021-12-21",
            "isShow": true
          }
        ]
      },
      {
        "id": 23,
        "roleName": "负责人",
        "roleId": "3",
        "gid": "3",
        "target": "甲方",
        "branch": "1",
        "sort": 1,
        "isend": 1,
        "salary": "10000",
        "msgCount": 0,
        "visitPeercount": 0,
        "visitLeaderCount": 12,
        "joiningCompany": 0,
        "recruitment": 0,
        "contractMoney": 0,
        "contractMoneyOne": 0,
        "businessSpending": 0,
        "recruitInterflow5": 0,
        "recruitInterflow10": 0,
        "recruitInterflow15": 0,
        "reinBureau": 0,
        "breakEvery": 0,
        "breakLeaderCompany": 0,
        "delFlag": 0,
        "updateTime": "2021-12-21",
        "createTime": "2021-12-21",
        "isShow": true,
        "list": [
          {
            "id": 23,
            "roleName": "负责人",
            "roleId": "3",
            "gid": "3",
            "target": "甲方",
            "branch": "1",
            "sort": 1,
            "isend": 1,
            "salary": "10000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 12,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-21",
            "createTime": "2021-12-21",
            "isShow": true
          }
        ]
      },
      {
        "id": 24,
        "roleName": "负责人",
        "roleId": "3",
        "gid": "3",
        "target": "甲方",
        "branch": "2",
        "sort": 1,
        "isend": 1,
        "salary": "10000",
        "msgCount": 0,
        "visitPeercount": 0,
        "visitLeaderCount": 0,
        "joiningCompany": 0,
        "recruitment": 0,
        "contractMoney": 0,
        "contractMoneyOne": 0.1,
        "businessSpending": 0,
        "recruitInterflow5": 0,
        "recruitInterflow10": 0,
        "recruitInterflow15": 0,
        "reinBureau": 0,
        "breakEvery": 0,
        "breakLeaderCompany": 0,
        "delFlag": 0,
        "updateTime": "2021-12-21",
        "createTime": "2021-12-21",
        "isShow": true,
        "list": [
          {
            "id": 24,
            "roleName": "负责人",
            "roleId": "3",
            "gid": "3",
            "target": "甲方",
            "branch": "2",
            "sort": 1,
            "isend": 1,
            "salary": "10000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0.1,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-21",
            "createTime": "2021-12-21",
            "isShow": true
          }
        ]
      }
    ]
  },
  {
    "gid": "4",
    "stageName": "第四阶段",
    "description": "将才",
    "hrPromoteLevelVOS": [
      {
        "id": 25,
        "roleName": "秘书处职员",
        "roleId": "1",
        "gid": "4",
        "target": "经营管理",
        "branch": "1",
        "sort": 1,
        "isend": 0,
        "salary": "12000",
        "msgCount": 0,
        "visitPeercount": 0,
        "visitLeaderCount": 0,
        "joiningCompany": 0,
        "recruitment": 10,
        "contractMoney": 0,
        "contractMoneyOne": 0,
        "businessSpending": 0,
        "recruitInterflow5": 0,
        "recruitInterflow10": 0,
        "recruitInterflow15": 0,
        "reinBureau": 0,
        "breakEvery": 0,
        "breakLeaderCompany": 0,
        "delFlag": 0,
        "updateTime": "2021-12-21",
        "createTime": "2021-12-21",
        "isShow": true,
        "list": [
          {
            "id": 25,
            "roleName": "秘书处职员",
            "roleId": "1",
            "gid": "4",
            "target": "经营管理",
            "branch": "1",
            "sort": 1,
            "isend": 0,
            "salary": "12000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 10,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-21",
            "createTime": "2021-12-21",
            "isShow": true
          }
        ]
      },
      {
        "id": 26,
        "roleName": "秘书处职员",
        "roleId": "1",
        "gid": "4",
        "target": "经营管理",
        "branch": "2",
        "sort": 1,
        "isend": 1,
        "salary": "15000",
        "msgCount": 0,
        "visitPeercount": 0,
        "visitLeaderCount": 0,
        "joiningCompany": 0,
        "recruitment": 0,
        "contractMoney": 0,
        "contractMoneyOne": 300,
        "businessSpending": 0,
        "recruitInterflow5": 0,
        "recruitInterflow10": 0,
        "recruitInterflow15": 0,
        "reinBureau": 0,
        "breakEvery": 0,
        "breakLeaderCompany": 0,
        "delFlag": 0,
        "updateTime": "2021-12-21",
        "createTime": "2021-12-21",
        "isShow": true,
        "list": [
          {
            "id": 26,
            "roleName": "秘书处职员",
            "roleId": "1",
            "gid": "4",
            "target": "经营管理",
            "branch": "2",
            "sort": 1,
            "isend": 1,
            "salary": "15000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 300,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-21",
            "createTime": "2021-12-21",
            "isShow": true
          }
        ]
      },
      {
        "id": 34,
        "roleName": "经营普通职员",
        "roleId": "2",
        "gid": "4",
        "target": "经营管理",
        "branch": "1",
        "sort": 1,
        "isend": 0,
        "salary": "12000",
        "msgCount": 0,
        "visitPeercount": 0,
        "visitLeaderCount": 10,
        "joiningCompany": 0,
        "recruitment": 0,
        "contractMoney": 0,
        "contractMoneyOne": 300,
        "businessSpending": 0,
        "recruitInterflow5": 0,
        "recruitInterflow10": 0,
        "recruitInterflow15": 0,
        "reinBureau": 0,
        "breakEvery": 0,
        "breakLeaderCompany": 0,
        "delFlag": 0,
        "updateTime": "2021-12-21",
        "createTime": "2021-12-21",
        "isShow": true,
        "list": [
          {
            "id": 34,
            "roleName": "经营普通职员",
            "roleId": "2",
            "gid": "4",
            "target": "经营管理",
            "branch": "1",
            "sort": 1,
            "isend": 0,
            "salary": "12000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 10,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-21",
            "createTime": "2021-12-21",
            "isShow": true
          },
          {
            "id": 35,
            "roleName": "经营普通职员",
            "roleId": "2",
            "gid": "4",
            "target": "甲方",
            "branch": "1",
            "sort": 2,
            "isend": null,
            "salary": null,
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 300,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-21",
            "createTime": "2021-12-21",
            "isShow": true
          }
        ]
      },
      {
        "id": 32,
        "roleName": "负责人",
        "roleId": "3",
        "gid": "4",
        "target": "经营管理",
        "branch": "1",
        "sort": 1,
        "isend": 0,
        "salary": null,
        "msgCount": 0,
        "visitPeercount": 0,
        "visitLeaderCount": 5,
        "joiningCompany": 0,
        "recruitment": 0,
        "contractMoney": 0,
        "contractMoneyOne": 0,
        "businessSpending": 0,
        "recruitInterflow5": 0,
        "recruitInterflow10": 0,
        "recruitInterflow15": 0,
        "reinBureau": 0,
        "breakEvery": 0,
        "breakLeaderCompany": 0,
        "delFlag": 0,
        "updateTime": "2021-12-21",
        "createTime": "2021-12-21",
        "isShow": true,
        "list": [
          {
            "id": 32,
            "roleName": "负责人",
            "roleId": "3",
            "gid": "4",
            "target": "经营管理",
            "branch": "1",
            "sort": 1,
            "isend": 0,
            "salary": null,
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 5,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-21",
            "createTime": "2021-12-21",
            "isShow": true
          }
        ]
      },
      {
        "id": 33,
        "roleName": "负责人",
        "roleId": "3",
        "gid": "4",
        "target": "甲方",
        "branch": "2",
        "sort": 1,
        "isend": 0,
        "salary": "15000",
        "msgCount": 0,
        "visitPeercount": 0,
        "visitLeaderCount": 0,
        "joiningCompany": 0,
        "recruitment": 0,
        "contractMoney": 0,
        "contractMoneyOne": 300,
        "businessSpending": 0,
        "recruitInterflow5": 0,
        "recruitInterflow10": 0,
        "recruitInterflow15": 0,
        "reinBureau": 0,
        "breakEvery": 0,
        "breakLeaderCompany": 0,
        "delFlag": 0,
        "updateTime": "2021-12-21",
        "createTime": "2021-12-21",
        "isShow": true,
        "list": [
          {
            "id": 33,
            "roleName": "负责人",
            "roleId": "3",
            "gid": "4",
            "target": "甲方",
            "branch": "2",
            "sort": 1,
            "isend": 0,
            "salary": "15000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 300,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-21",
            "createTime": "2021-12-21",
            "isShow": true
          }
        ]
      },
      {
        "id": 30,
        "roleName": "分管领导",
        "roleId": "4",
        "gid": "4",
        "target": "甲方",
        "branch": "1",
        "sort": 1,
        "isend": 0,
        "salary": "12000",
        "msgCount": 0,
        "visitPeercount": 0,
        "visitLeaderCount": 0,
        "joiningCompany": 0,
        "recruitment": 5,
        "contractMoney": 0,
        "contractMoneyOne": 300,
        "businessSpending": 0,
        "recruitInterflow5": 0,
        "recruitInterflow10": 0,
        "recruitInterflow15": 0,
        "reinBureau": 0,
        "breakEvery": 0,
        "breakLeaderCompany": 0,
        "delFlag": 0,
        "updateTime": "2021-12-21",
        "createTime": "2021-12-21",
        "isShow": true,
        "list": [
          {
            "id": 30,
            "roleName": "分管领导",
            "roleId": "4",
            "gid": "4",
            "target": "甲方",
            "branch": "1",
            "sort": 1,
            "isend": 0,
            "salary": "12000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 5,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-21",
            "createTime": "2021-12-21",
            "isShow": true
          },
          {
            "id": 31,
            "roleName": "分管领导",
            "roleId": "4",
            "gid": "4",
            "target": "甲方",
            "branch": "1",
            "sort": 2,
            "isend": null,
            "salary": null,
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 300,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-21",
            "createTime": "2021-12-21",
            "isShow": true
          }
        ]
      }
    ]
  },
  {
    "gid": "5",
    "stageName": "第五阶段",
    "description": "帅才",
    "hrPromoteLevelVOS": [
      {
        "id": 27,
        "roleName": "分管领导",
        "roleId": "4",
        "gid": "5",
        "target": "经营管理",
        "branch": "1",
        "sort": 1,
        "isend": 0,
        "salary": "15000",
        "msgCount": 0,
        "visitPeercount": 0,
        "visitLeaderCount": 10,
        "joiningCompany": 0,
        "recruitment": 0,
        "contractMoney": 0,
        "contractMoneyOne": 0,
        "businessSpending": 0,
        "recruitInterflow5": 0,
        "recruitInterflow10": 0,
        "recruitInterflow15": 0,
        "reinBureau": 0,
        "breakEvery": 10,
        "breakLeaderCompany": 20,
        "delFlag": 0,
        "updateTime": "2021-12-21",
        "createTime": "2021-12-21",
        "isShow": true,
        "list": [
          {
            "id": 27,
            "roleName": "分管领导",
            "roleId": "4",
            "gid": "5",
            "target": "经营管理",
            "branch": "1",
            "sort": 1,
            "isend": 0,
            "salary": "15000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 10,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-21",
            "createTime": "2021-12-21",
            "isShow": true
          },
          {
            "id": 28,
            "roleName": "分管领导",
            "roleId": "4",
            "gid": "5",
            "target": "经营管理",
            "branch": "1",
            "sort": 2,
            "isend": 0,
            "salary": "30000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 10,
            "breakLeaderCompany": 0,
            "delFlag": 0,
            "updateTime": "2021-12-21",
            "createTime": "2021-12-21",
            "isShow": true
          },
          {
            "id": 29,
            "roleName": "分管领导",
            "roleId": "4",
            "gid": "5",
            "target": "经营管理",
            "branch": "1",
            "sort": 3,
            "isend": 0,
            "salary": "50000",
            "msgCount": 0,
            "visitPeercount": 0,
            "visitLeaderCount": 0,
            "joiningCompany": 0,
            "recruitment": 0,
            "contractMoney": 0,
            "contractMoneyOne": 0,
            "businessSpending": 0,
            "recruitInterflow5": 0,
            "recruitInterflow10": 0,
            "recruitInterflow15": 0,
            "reinBureau": 0,
            "breakEvery": 0,
            "breakLeaderCompany": 20,
            "delFlag": 0,
            "updateTime": "2021-12-21",
            "createTime": "2021-12-21",
            "isShow": true
          }
        ]
      }
    ]
  }
]

    },
    computed: {
      reWorkType() {
        return workType
      },
      reQuery() {
        return this.$route.query
      }
    },
    watch:{
      'reQuery':{
        handler(obj) {
          // let obj = {
          //   username:'黄兴',
          //   userId:'ac6f0104-64fd-4df4-8fe0-2c9c8baeaef6'
          // }
          // return
          this.integrateData(obj);
        },
        immediate:true
      },
      '$refs.steps':{
        handler(val) {
          // return
            this.$nextTick(() => {
              setTimeout(() => {
                let chain = this.$refs.chain;//外链
                // console.log('stepItem',stepItem);
                // console.log('chain',chain);
                if(!chain) return
                let chainH = chain.map(el =>el.offsetHeight);
                let maxChainH = Math.max.apply(null, chainH);
                this.chainH = maxChainH;


                let step = this.$refs.step;
                let gids = step.map(el =>el.$attrs['data-gid']);
                let maxGid = Math.max.apply(null, gids);
                let stepH = step.map(el =>el.$children[0].$children);
                
                // console.log('=======stepH',stepH)
                // console.log('=======xx',xx)
                let temp = {};
                let arr = [];
                for (let index = 0; index < maxGid; index++) {
                  const elem = gids[index];
                  temp[elem] = stepH[index];
                };
                // console.log('=========temp',temp)
                for (let index = 0; index < maxGid; index++) {
                  const el = returnElem(index);
                  arr.push(el);
                }
                function returnElem(i) {
                  let temA = gids.map(elem=> temp[elem][i])
                  // console.log('========',temA)
                  let strC = temA.map(el=> el?el.$el.lastChild.clientHeight?el.$el.lastChild.clientHeight:0:0)
                  // console.log('=========strC',strC)
                  let lens = Math.max.apply(null, strC)
                  return  lens
                }
                console.log('=========arr',arr)
                this.elH = arr;
              }, 1600);
                
            });
        },
        immediate:true,
        deep:true
      },
    },
    methods: {
      //整合数据
      integrateData(obj) {
        let arr = this.reQuery.userId
                ? [this.initData('',obj),this.initCurUserChart(obj)]
                : [this.initData('',obj)]
        this.spinning = true;
        new Promise((resolve,reject)=>{
          Promise.all(arr).then(resArr => {
            // console.log('======res整个',resArr);
            let temp1 = resArr[0]?resArr[0]:[];
            let temp2 = resArr[1]?resArr[1]:[];
            temp1.forEach((el,idx) => {
              //阶段
              /*****判断gid阶段是否一致****** */
              if(temp2[idx] && (el.gid == temp2[idx].gid)) {
                /*****用户所处阶段的高亮****** */
                el.gidFlag = (temp2[idx].gradeId == temp2[idx].gid);
                // el.gidFlag = true;
                /******合并员工的工作清单****** */
                el.workList = [];
                el.workTemp = [];
                //sort分支--线条
                el.hrPromoteLevelVOS.forEach((sort,index) => {
                  // console.log('======el.sort',sort);
                  // console.log('======temp2[idx]',temp2[idx].hrPromoteLevelVOS[index]);
                  /*****判断sort线路是否一致****** */
                  if(temp2[idx].hrPromoteLevelVOS[index] && (sort.id == temp2[idx].hrPromoteLevelVOS[index].id)) {
                   /*****判断分支branch的任务是否存在****** */
                    let temp = [];
                    let tempA = temp2[idx].hrPromoteLevelVOS[index].list;
                    tempA.forEach(it => {
                      for(let key in this.reWorkType) {
                        if(it[key]) {
                          temp.push(it);
                        }
                      }
                    });
                    // console.log('=======temp',temp);
                    sort.sortFlag = !!temp.length;
                    sort.curList = temp;

                    if(sort.sortFlag) {
                      el.workTemp.push(temp2[idx].hrPromoteLevelVOS[index]);
                      //默认打开的项
                      this.activeKey.push(`${sort.id}`);
                    }
                  }
                })
                //去重
                let obj = {}
                for(let key in this.reWorkType) {
                  el.workTemp.forEach(el => {
                    if(el[key]) {
                      obj[key] = el[key];
                    }
                  }); 
                }
                el.workList.push(obj);
                // console.log('=========arr',arr);
              };

            });
            // console.log('=========temp1',temp1)
            resolve(temp1);
          }).catch((err) => reject(err.message))
        }).then(res => {
          // console.log('=========res',res);
          this.promoteChartData = res;
          this.spinning = false;
        }).catch(err => {
          this.$notification.error({
            message: '错误提示',
            description: err,
            duration: 0,
          })
          this.spinning = false;
        })
      },
      //初始化晋升图&工单图---总览图
      initData(gid,obj) {
        return getPromoteChart({
            gid,
            userId:obj.userId
        }).then(res => {
          if(res.success) {
            // this.promoteChartData = res.result;
            return res.result || []
          }else {
            this.$notification.error({
                message: '错误提示',
                description: res.message,
                duration: 0,
            })
            return 
          }
        })
      },
      //当前人的晋升图&工单---个人
      initCurUserChart(obj){
        return getCurrentUserChart({
          userName:obj.username,
          userId:obj.userId
        }).then(res => {
          if(res.success) {
            // this.promoteChart = res.result;
            return res.result || []
          }else {
            this.$notification.error({
                message: '错误提示',
                description: res.message,
                duration: 0,
            })
            return 
          }
        })
      },
      //打开外链
      openChain(key,type) {
        console.log('=========打开外连',key,type);
        let record = {
          userName:this.reQuery.username,
          userId:this.reQuery.userId,
          key:key,
          title:type
        };
        this.$refs.infoForm.open(record);
        this.$refs.infoForm.title = type+'表';
        this.$refs.infoForm.disableSubmit = true;


        /*********下个版本的修改*************/
        // let routeUrl = this.$router.resolve({
        //   path: '/HR-platform/detailedList',
        //   query: {...record}
        // });
        // console.log(routeUrl);
        // window.open(routeUrl.href, '_blank');
        /*********下个版本的修改*************/
      },
      //招聘库新增
      handlerRecruit() {
        let record = {
          username:this.reQuery.username,
          userId:this.reQuery.userId,
          isRecruit:true
        }
        this.$refs.modelForm.add(record);
        this.$refs.modelForm.title = '招聘人员新增';
        this.$refs.modelForm.disableSubmit = false;
      },
      //交流-人才库新增
      handlerTalent() {
        let record = {
          createUserName:this.reQuery.username,
          createUserId:this.reQuery.userId,
          isRecruit:false
        }
        this.$refs.modelForm.add(record);
        this.$refs.modelForm.title = '交流人才新增';
        this.$refs.modelForm.disableSubmit = false;
      },
      //招聘&人才新增成功--操作刷新图片
      subOk() {
        this.integrateData(this.reQuery);
      }
    }
  }
</script>
<style scoped lang="less">
.gid_color{
  color:#fff !important;
}
.gid_style {
  border-color: #40a9ff;
  background-color: #40a9ff;
  box-shadow: 0px 8px 16px 0px rgba(3, 86, 138, 0.9);
}
.cur_item {
  /deep/.ant-steps-item-title{.gid_style();.gid_color();padding: 0 10px !important;}
  /deep/.ant-steps-item-subtitle{.gid_color()}
  /deep/.ant-steps-item-icon{.gid_style()}
  /deep/.ant-steps-icon{.gid_color()}
}
.sort_list_doing {
  background-color: #ecc205 !important;
}
.sort_list_done {
  background-color: darkseagreen !important;
}
.sort_list_mo {
  background-color: #ccf3f3 !important;
}

.isSel{/deep/.item_box {box-shadow: 0px 8px 16px 0px rgba(3, 86, 138, 0.902);width: 98%;margin: auto;}}

.chain_item{
  display: flex;
  flex-direction: column;
}
  .seal_heard{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #002b45;
    color: #fff;
    font-size: 14px;
    .back{
        // width: 90px;
        height: 100%;
        line-height: 50px;
        text-align: center;
        margin-left: 10px;
        // border-right: 1px solid #26475a;
        cursor: pointer;
    }
    .title{flex:1;text-align: center;}
    .btn{margin-right: 20px;button{margin-left: 10px;}}
  }
  .main {
    padding: 20px;
    height: calc(100vh - 50px);
    overflow-y: auto;
    &::-webkit-scrollbar{
        width: 8px;
        height: 8px;
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb{background-color: #ddd;}
  }
  .promote_echart {
    width: 100%;
    /deep/.ant-collapse-item{border-top:0px solid #d9d9d9;border-bottom: none;}
    /deep/.ant-collapse-content-box{padding: 5px 0;background-color: #fff;}

    /deep/.ant-collapse-borderless {background-color:#fff;}
    /deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding:10px 0;padding-left:20px;
      .ant-collapse-arrow {left:-4px;font-size: 16px;}
    }

    /deep/.ant-spin{color:#002b45 !important;}
    /deep/.ant-spin-dot-item {background-color:#002b45 !important;}
    /deep/.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item{flex:1; margin-right: 0 !important;padding-right: 30px;box-sizing: border-box;}
    /deep/.ant-steps-item-content{width:100%;}
    /deep/.ant-steps-item-container{background-color: #fff;}
    /deep/.ant-steps-item-description{margin-top: 20px;max-width: 100% !important;}

    
    .sort_list_column {
      flex-direction: column;
    }
    .sort_list{
      display: flex;
      background-color: aliceblue;
      margin:10px 0;
      .sort_list_step{
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 0 4px;
        .list_step_sort{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: baseline;
        }
      }
      .tip-icon{
          display: inline-block;
          margin-right: 4px;
          // color: #1890FF;
          font-size: 12px;
          font-family: SimSun, sans-serif;
          line-height: 2;
      }
      .tip_money{
        font-size: 12px;
        padding: 8px 0;
        font-family: SimSun, sans-serif;
        color: red;
        position: relative;
        .step-icon{
          position: absolute;
          bottom:-10px;
          left:10px;
          font-size: 34px;
          vertical-align: middle;
          color:#000000a6 !important;
        }
      }
    };
    .hover_active {position: relative;}
    .hover_active:hover .unordered_list {display: flex !important;}
    .hover_active:hover .active {color:cadetblue;cursor: pointer;}
    .unordered_list{
      // width: 300px;
      padding: 10px;
      background-color: #fff;
      position: absolute;
      display: none !important;
      z-index: 1000;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      // display:flex;
      flex-direction: column;
    }
    
  }
  .foot {width: 100%;height: 30px;line-height: 30px;text-align: center;font-size: 12px;background-color: #f6f6f6;border-top: 1px solid #ebebeb;}
</style>